import { render, staticRenderFns } from "./Housing.vue?vue&type=template&id=aa4f230c&scoped=true"
import script from "./Housing.vue?vue&type=script&lang=js"
export * from "./Housing.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa4f230c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/mnt/www/jenkins/jenkins_home/workspace/product/qianlimu/online/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('aa4f230c')) {
      api.createRecord('aa4f230c', component.options)
    } else {
      api.reload('aa4f230c', component.options)
    }
    module.hot.accept("./Housing.vue?vue&type=template&id=aa4f230c&scoped=true", function () {
      api.rerender('aa4f230c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/location/SupportingModel/Housing.vue"
export default component.exports