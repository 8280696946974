var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "over-view-wrap",
    },
    [
      _vm.address
        ? _c(
            "div",
            {
              staticClass: "address-wrap",
              class: { "address-no-icon": !_vm.icon },
            },
            [
              _vm.icon
                ? _c("i", { staticClass: "address-icon", class: _vm.icon })
                : _vm._e(),
              _c("div", { staticClass: "address" }, [
                _vm._v(_vm._s(_vm.address)),
              ]),
            ]
          )
        : _vm._e(),
      _vm.list && _vm.list.length > 0
        ? _c("div", { staticClass: "over-view-list" }, [
            _c(
              "ul",
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "li",
                  {
                    key: "over_list_" + index,
                    style: {
                      "background-image": "url(" + item.pic + ")",
                    },
                  },
                  [
                    _c("div", { staticClass: "over-label" }, [
                      _vm._v(_vm._s(item.label)),
                    ]),
                    _c("div", { staticClass: "over-cont" }, [
                      _c("span", { staticClass: "over-val" }, [
                        _vm._v(
                          _vm._s(
                            item.value || item.value === 0 ? item.value : "--"
                          )
                        ),
                      ]),
                      _c("span", { staticClass: "over-unit" }, [
                        _vm._v(_vm._s(item.unit)),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }