<template>
  <div class="card-box">
    <div class="card-title" v-if="title">{{ title }}</div>
    <div class="card-content" :style="{ height: height }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "auto",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.card-box {
  height: auto;
  .card-title {
    margin: 16px 0px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
    line-height: 20px;
    &::before {
      content: "";
      display: inline-block;
      width: 3px;
      height: 14px;
      background: #3519fb;
      border-radius: 2px;
      margin-right: 8px;
    }
  }
  .card-content {
    height: auto;
  }
}
</style>
