var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabs-box" },
    [
      _c(
        "Tabs",
        {
          on: { "on-click": _vm.tabClick },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        _vm._l(_vm.tabList, function (item) {
          return _c("TabPane", {
            key: item.label,
            attrs: { label: item.name, name: item.label },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }