var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "supporting-box" },
    [
      _vm.isShowOverView
        ? [
            _c("OverView", {
              attrs: {
                address: _vm.address,
                icon: "icon-marker-oval",
                polygon: _vm.polygon,
                list: _vm.overviewList,
              },
            }),
          ]
        : _vm._e(),
      _c("CustomTabs", {
        attrs: { active: _vm.activeTab, tabList: _vm.tabList },
        on: { activeTab: _vm.clickTab },
      }),
      _c(_vm.activeTab, { tag: "component", attrs: { polygon: _vm.polygon } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }