import { render, staticRenderFns } from "./Hospital.vue?vue&type=template&id=41467ded&scoped=true"
import script from "./Hospital.vue?vue&type=script&lang=js"
export * from "./Hospital.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41467ded",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/mnt/www/jenkins/jenkins_home/workspace/product/qianlimu/online/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('41467ded')) {
      api.createRecord('41467ded', component.options)
    } else {
      api.reload('41467ded', component.options)
    }
    module.hot.accept("./Hospital.vue?vue&type=template&id=41467ded&scoped=true", function () {
      api.rerender('41467ded', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/location/SupportingModel/Hospital.vue"
export default component.exports