var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "table-box",
    },
    [
      _c("Table", {
        staticClass: "table-view",
        attrs: { columns: _vm.columns, data: _vm.list },
      }),
      _c(
        "div",
        { staticClass: "table-page" },
        [
          !_vm.isLogin() && !_vm.isPage
            ? [
                _c("div", { staticClass: "visitor-login" }, [
                  _c(
                    "div",
                    {
                      staticClass: "visitor-login-btn",
                      attrs: { role: "button" },
                      on: { click: _vm.gotoLogin },
                    },
                    [
                      _vm._v(" 登录查看全部信息"),
                      _c("img", {
                        staticClass: "visitor-login-icon",
                        attrs: {
                          src: require("@/assets/images/double-arror.png"),
                          width: "14",
                          height: "14",
                        },
                      }),
                    ]
                  ),
                ]),
              ]
            : _vm._e(),
          _vm.isPage
            ? _c("Page", {
                attrs: {
                  total: _vm.total,
                  current: _vm.page,
                  "page-size": _vm.pageSize,
                },
                on: {
                  "on-change": _vm.onChange,
                  "on-page-size-change": _vm.onPageSizeChange,
                },
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }