<template>
  <!-- 小区 -->
  <div class="housing-box">
    <CustomData :dataList="detailsCard"></CustomData>
    <CustomCard title="小区平均房价分布" height="230px">
      <PieChart :echartData="pieData"></PieChart>
    </CustomCard>
    <CustomCard title="小区详细信息">
      <CustomTable
        :columns="columns"
        :list="tableList"
        :page="pages.page"
        :pageSize="pages.size"
        :total="total"
        :isPage="total > pages.size"
        @onChange="onPageChange"
      ></CustomTable>
    </CustomCard>
  </div>
</template>

<script>
import CustomData from "@/components/CustomData.vue";
import CustomCard from "@/components/CustomCard.vue";
import CustomTable from "@/components/CustomTable.vue";
import PieChart from "@/components/chart/pieChart.vue";
import { GetCommonInfo } from "@/service";
import { formatFieldsList2 } from "js/utils.js";
export default {
  props: {
    polygon: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 概览详情卡片数据
      detailsCard: [],
      // 饼图数据
      pieData: [],
      // 表格表头
      columns: [
        {
          title: "序号",
          type: "index",
          width: 60,
          align: "center",
          className: "serial-number",
        },
        {
          title: "小区名称",
          key: "小区名称",
        },
        {
          title: "均价（元/m²）",
          key: "均价",
          width: 120,
        },
      ],
      // 表格数据
      tableList: [],
      // 表格总数
      total: 0,
      // 表格分页
      pages: {
        page: 1,
        size: 10000,
      },
    };
  },
  components: {
    CustomCard,
    CustomData,
    PieChart,
    CustomTable,
  },
  watch: {
    polygon() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getOverview();
      this.getAverage();
      this.getDetails();
    },
    // 获取小区概览
    async getOverview() {
      let params = {
        identify: "sg-data-位置-小区概览",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: ["小区数量", "住宅总户数", "平均房价"],
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let detailsCard = JSON.parse(JSON.stringify([
          { title: "小区数量", value: -1, unit: "个" },
          { title: "住宅总户数", value: -1, unit: "户" },
          { title: "平均房价", value: -1, unit: "元/m²" },
        ]));
        let details = res.data.items ? res.data.items[0] : [];
        detailsCard[0].value = details[0] || 0;

        if (details[1] === null) {
          detailsCard.splice(1, 1);
          detailsCard[1].value = details[2] ? details[2].toFixed(2) : 0;
        } else {
          detailsCard[1].value = details[1] ? details[1].toFixed(1) : 0;
          detailsCard[2].value = details[2] ? details[2].toFixed(2) : 0;
        }
        this.detailsCard = JSON.parse(JSON.stringify(detailsCard));
      }
    },
    // 获取小区房价均价分布
    async getAverage() {
      let params = {
        identify: "sg-data-位置-小区房价均价分布",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: [
          "0.8-3.8万元",
          "3.8-6.9万元",
          "6.9-9.9万元",
          "9.9-13.0万元",
          "13.0-16.0万元",
        ],
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let details = res.data.items ? res.data.items[0] : [];
        this.pieData = [];
        details.forEach((item, index) => {
          this.pieData.push({
            name: res.data.fields[index],
            value: item,
          });
        });
      }
    },
    // 获取小区详细信息
    async getDetails() {
      let params = {
        identify: "sg-data-位置-小区详细信息",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: ["小区名称", "均价"],
        pages: this.pages,
      };
      let res = await GetCommonInfo(params);

      if (res && res.code === 200) {
        let list = formatFieldsList2(res.data || {});
        this.tableList = list;
        this.total = res.data.pages.total;
      }
    },
    // 小区详细信息表格页码改变
    async onPageChange(page) {
      this.pages.page = page;
      this.$emit("startLoading");
      await this.getDetails();
      this.$emit("endLoading");
    },
  },
};
</script>

<style lang="less" scoped></style>
