var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "business-box" },
    [
      _c("CustomData", { attrs: { dataList: _vm.detailsCard1 } }),
      _c(
        "CustomCard",
        { attrs: { title: "商场详情信息" } },
        [
          _c("CustomTable", {
            attrs: {
              columns: _vm.columns1,
              list: _vm.tableList1,
              page: _vm.pages1.page,
              pageSize: _vm.pages1.size,
              total: _vm.total1,
            },
            on: { onChange: _vm.onPageChange1 },
          }),
        ],
        1
      ),
      _c(
        "CustomCard",
        { attrs: { title: "便利店分析" } },
        [_c("CustomData", { attrs: { dataList: _vm.detailsCard2 } })],
        1
      ),
      _c(
        "CustomCard",
        { attrs: { title: "超市/便利店详情信息" } },
        [
          _c("CustomTable", {
            attrs: {
              columns: _vm.columns2,
              list: _vm.tableList2,
              page: _vm.pages2.page,
              pageSize: _vm.pages2.size,
              total: _vm.total1,
            },
            on: { onChange: _vm.onPageChange2 },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }