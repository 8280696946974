var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "incorporated-box" },
    [
      _c("CustomData", { attrs: { dataList: _vm.detailsCard } }),
      _c(
        "CustomCard",
        { attrs: { title: "企业类型分布", height: "230px" } },
        [_c("PieChart", { attrs: { echartData: _vm.pieData } })],
        1
      ),
      _c(
        "CustomCard",
        { attrs: { title: "企业详情信息" } },
        [
          _c("CustomTable", {
            attrs: {
              columns: _vm.columns,
              list: _vm.tableList,
              page: _vm.pages.page,
              pageSize: _vm.pages.size,
              total: _vm.total,
            },
            on: { onChange: _vm.onPageChange },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }