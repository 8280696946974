<template>
  <div class="table-box" v-loading="loading">
    <Table class="table-view" :columns="columns" :data="list"></Table>
    <div class="table-page">
      <template v-if="!isLogin() && !isPage">
        <div class="visitor-login">
          <div role="button" class="visitor-login-btn" @click="gotoLogin">
            登录查看全部信息<img
              class="visitor-login-icon"
              :src="require('@/assets/images/double-arror.png')"
              width="14"
              height="14"
            />
          </div>
        </div>
      </template>
      <Page
        v-if="isPage"
        :total="total"
        :current="page"
        :page-size="pageSize"
        @on-change="onChange"
        @on-page-size-change="onPageSizeChange"
      />
    </div>
  </div>
</template>
<script>
import { login, isLogin } from "@/assets/javascript/utils.js";
export default {
  name: "CustomTable",
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
    isPage: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 5,
    },
  },
  watch: {
    list: {
      handler: function () {
        this.loading = false;
      },
      deep: true,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    // 页码改变的回调，返回改变后的页码
    onChange(page) {
      this.$emit("onchange", page);
    },
    isLogin,
    gotoLogin: login,
    // 切换每页条数时的回调，返回切换后的每页条数
    onPageSizeChange(size) {
      this.$emit("onPageSizeChange", size);
    },
  },
};
</script>
<style lang="less" scoped>
.table-box {
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  border: 1px solid #eeeeee;
  position: relative;
  .visitor-login {
    position: static !important;
    width: 100% !important;
    height: 80px !important;
    .visitor-login-btn:hover {
      cursor: pointer;
    }
  }
  .table-view {
    /deep/ .ivu-table-header {
      .ivu-table-cell {
        font-weight: 500;
      }
      thead tr th {
        padding: 12px 0;
      }
      .serial-number {
        .ivu-table-cell {
          color: #000000;
        }
      }
    }
    /deep/ .serial-number {
      .ivu-table-cell {
        color: #757e96;
      }
    }
    /deep/ .ivu-table-cell {
      padding-right: 0px;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      line-height: 20px;
    }
  }
  .table-page {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    /deep/ .ivu-page-prev,
    /deep/ .ivu-page-next,
    /deep/ .ivu-page-item {
      min-width: 20px;
      // width: 20px;
      height: 20px;
      line-height: 20px;
      &:hover {
        color: #ffffff;
        background: #3519fb;
        a {
          color: #ffffff;
        }
      }
    }
    /deep/ .ivu-select-selection {
      line-height: 22px;
      padding: 5px 12px;
      width: auto;
      height: auto;
      &:hover {
        // color: #ffffff;
        border-color: #3519fb;
      }
    }
    /deep/ .ivu-select-visible .ivu-select-selection {
      border-color: #3519fb;
    }
    /deep/ .ivu-page-item-active {
      background: #3519fb;
      color: #ffffff;
      &:hover a,
      a {
        color: #ffffff;
        background: #3519fb;
      }
    }
    /deep/ .ivu-select-item-selected {
      color: #3519fb;
    }
    /deep/ .ivu-page-item-jump-next {
      height: 20px;
      line-height: 20px;
      .ivu-icon-ios-arrow-forward {
        color: #3519fb;
      }
    }
  }
}
</style>
