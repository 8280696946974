<template>
  <!-- 商业 -->
  <div class="business-box">
    <CustomData :dataList="detailsCard1"></CustomData>
    <CustomCard title="商场详情信息">
      <CustomTable
        :columns="columns1"
        :list="tableList1"
        :page="pages1.page"
        :pageSize="pages1.size"
        :total="total1"
        @onChange="onPageChange1"
      ></CustomTable>
    </CustomCard>
    <CustomCard title="便利店分析">
      <CustomData :dataList="detailsCard2"></CustomData>
    </CustomCard>
    <CustomCard title="超市/便利店详情信息">
      <CustomTable
        :columns="columns2"
        :list="tableList2"
        :page="pages2.page"
        :pageSize="pages2.size"
        :total="total1"
        @onChange="onPageChange2"
      ></CustomTable>
    </CustomCard>
  </div>
</template>

<script>
import CustomData from "@/components/CustomData.vue";
import CustomCard from "@/components/CustomCard.vue";
import CustomTable from "@/components/CustomTable.vue";
import { GetCommonInfo } from "@/service";
import { formatFieldsList2 } from "js/utils.js";
export default {
  props: {
    polygon: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 概览详情卡片数据
      detailsCard1: [
        { title: "商场数量", value: -1, unit: "个" },
        { title: "超市数量", value: -1, unit: "个" },
        { title: "便利店数量", value: -1, unit: "个" },
      ], // 商业信息概览
      detailsCard2: [
        { title: "万人拥有量", value: -1, unit: "" },
        { title: "门店占比", value: -1, unit: "%" },
        { title: "密度", value: -1, unit: "个/km²" },
      ], // 便利店分析
      // 表格表头
      columns1: [
        {
          title: "序号",
          type: "index",
          width: 60,
          align: "center",
          className: "serial-number",
        },
        {
          title: "商场名称",
          key: "商场名称",
        },
        {
          title: "地址",
          key: "地址",
        },
      ], // 商场
      columns2: [
        {
          title: "序号",
          type: "index",
          width: 60,
          align: "center",
          className: "serial-number",
        },
        {
          title: "名称",
          key: "名称",
        },
        {
          title: "地址",
          key: "地址",
          width: 120,
        },
      ], // 超市/便利店
      // 表格数据
      tableList1: [], // 商场
      tableList2: [], // 超市/便利店
      // 表格总数
      total1: 0, // 商场
      total2: 0, // 超市/便利店
      // 表格分页
      pages1: {
        page: 1,
        size: 10000,
      }, // 商场
      pages2: {
        page: 1,
        size: 10000,
      }, // 超市/便利店
    };
  },
  components: {
    CustomCard,
    CustomData,
    CustomTable,
  },
  watch: {
    polygon() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getOverview1();
      this.getOverview2();
      this.getDetails1();
      this.getDetails2();
    },
    // 获取商业概览
    async getOverview1() {
      let params = {
        identify: "sg-data-位置-商业",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: ["商场", "超市", "便利店"],
      };
      let res = await GetCommonInfo(params);
      // console.log("商业", res);
      if (res && res.code === 200) {
        let details = res.data.items ? res.data.items[0] : [];
        this.detailsCard1[0].value = details[0] || 0;
        this.detailsCard1[1].value = details[1] || 0;
        this.detailsCard1[2].value = details[2] || 0;
      }
    },
    // 获取超市/便利店概览
    async getOverview2() {
      let params = {
        identify: "sg-data-位置-便利店",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: ["每万人均拥有量", "覆盖率", "便利店密度", "便利店总数"],
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let details = res.data.items ? res.data.items[0] : [];
        this.detailsCard2[0].value = details[0] || 0;
        this.detailsCard2[1].value = details[1] || 0;
        this.detailsCard2[2].value = details[2] ? details[2].toFixed(2) : 0;
      }
    },
    // 获取商场详细信息
    async getDetails1() {
      let params = {
        identify: "sg-data-位置-商场详细信息",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: ["商场名称", "地址"],
        pages: this.pages1,
      };
      let res = await GetCommonInfo(params);
      // console.log("商场详细信息", res);
      if (res && res.code === 200) {
        let list = formatFieldsList2(res.data || {});
        this.tableList1 = list;
        this.total1 = res.data.pages.total;
      }
    },
    // 获取超市/便利店详细信息
    async getDetails2() {
      let params = {
        identify: "sg-data-位置-超市/便利店详细信息",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: ["名称", "地址"],
        pages: this.pages2,
      };
      let res = await GetCommonInfo(params);
      // console.log("超市/便利店详细信息", res);
      if (res && res.code === 200) {
        let list = formatFieldsList2(res.data || {});
        this.tableList2 = list;
        this.total2 = res.data.pages.total;
      }
    },
    // 商场详细信息表格页码改变
    onPageChange1(page) {
      this.pages1.page = page;
      this.getDetails1();
    },
    // 超市/便利店详细信息表格页码改变
    onPageChange2(page) {
      this.pages2.page = page;
      this.getDetails2();
    },
  },
};
</script>

<style lang="less" scoped></style>
