<template>
  <div class="data-box" v-loading="loading">
    <div
      class="data-item"
      :class="{
        'no-after':
          dataList.length >= rowNumber
            ? (index + 1) % rowNumber === 0
            : (index + 1) % dataList.length === 0,
        'no-margin-top': index < rowNumber,
      }"
      v-for="(item, index) in dataList"
      :key="item.title"
      :style="{
        width:
          dataList.length >= rowNumber
            ? Math.floor(100 / rowNumber) + '%'
            : Math.floor(100 / dataList.length) + '%',
      }"
    >
      <div>{{ item.title }}</div>
      <div>
        <span class="data-item-value">
          <template v-if="item.unit == '户'">
            {{ item.value || item.value === 0 ? parseInt(item.value) : "0" }}
          </template>
          <template v-else>
            {{ item.value || item.value === 0 ? item.value : "0" }}
          </template>
        </span>
        {{ item.unit }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomData",
  props: {
    dataList: {
      type: Array,
      default: () => [
        // { title: "小区数量", value: 0, unit: "个" },
        // { title: "住宅总户数", value: 0, unit: "户" },
        // { title: "平均房价", value: 0, unit: "元/m²" },
      ],
    },
    // 每行显示几个
    rowNumber: {
      type: Number,
      default: 3,
    },
  },
  watch: {
    dataList: {
      handler: function (val) {
        if (val[0].value === -1) return;
        this.loading = false;
      },
      deep: true,
      // immediate: true,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    isData() {
      return this.dataList.some((item) => item.value);
    },
  },
  components: {},
};
</script>

<style lang="less" scoped>
.data-box {
  background: #fbfbfe;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.02);
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 12px 0;
  position: relative;
  .data-item {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: #848295;
    line-height: 22px;
    position: relative;
    margin-top: 10px;
    .data-item-value {
      font-size: 16px;
      color: #000000;
      font-weight: 500;
    }
    &::after {
      content: "";
      width: 1px;
      height: 25px;
      background: rgba(132, 130, 149, 0.2);
      position: absolute;
      right: 0;
      top: 10px;
    }
  }
  .no-after {
    &::after {
      display: none;
    }
  }
  .no-margin-top {
    margin-top: 0px;
  }
}
</style>
