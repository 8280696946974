<template>
  <div class="over-view-wrap" v-loading="loading">
    <div
      class="address-wrap"
      :class="{ 'address-no-icon': !icon }"
      v-if="address"
    >
      <i class="address-icon" :class="icon" v-if="icon"></i>
      <div class="address">{{ address }}</div>
    </div>
    <div class="over-view-list" v-if="list && list.length > 0">
      <ul>
        <li
          v-for="(item, index) in list"
          :key="'over_list_' + index"
          :style="{
            'background-image': 'url(' + item.pic + ')',
          }"
        >
          <div class="over-label">{{ item.label }}</div>
          <div class="over-cont">
            <span class="over-val">{{
              item.value || item.value === 0 ? item.value : "--"
            }}</span>
            <span class="over-unit">{{ item.unit }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["icon", "address", "list"],
  watch: {
    list: {
      handler: function () {
        this.loading = false;
      },
      deep: true,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
};
</script>

<style lang="less">
.over-view-wrap {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: hsla(0, 0%, 0%, 0.9);
  position: relative;
  .address-wrap {
    .address-icon,
    .address {
      vertical-align: middle;
    }
    .address-icon {
      width: 38px;
      height: 38px;
      display: inline-block;
    }
    .address {
      width: calc(100% - 48px);
      margin-left: 10px;
      display: inline-block;
    }
    &.address-no-icon {
      .address {
        width: 100%;
        margin-left: 0px;
      }
    }
  }
  .over-view-list {
    margin: 10px 0;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 0 -5px;
      li {
        width: calc(33.3% - 10px);
        font-size: 12px;
        font-weight: 500;
        line-height: 22px;
        margin: 5px;
        padding: 7px 0 9px 0;
        text-align: center;
        color: hsla(246, 8%, 55%, 1);
        .over-val {
          font-size: 16px;
          color: hsla(0, 0%, 0%, 1);
        }
        .over-label {
        }
        .over-cont {
          .over-unit {
          }
        }
      }
    }
  }
}
</style>
