var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-box" }, [
    _vm.title
      ? _c("div", { staticClass: "card-title" }, [_vm._v(_vm._s(_vm.title))])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "card-content", style: { height: _vm.height } },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }