<template>
  <!-- 写字楼 -->
  <div class="building-box">
    <CustomData :dataList="detailsCard"></CustomData>
    <CustomCard title="写字楼占地面积分布（%）" height="230px">
      <LineChart
        :echartData="lineData"
        :lineColor="'#3519FB'"
        :textColor="'#848295'"
        :colorStopsStart="'rgba(62,78,255,0.1)'"
        :colorStopsEnd="'rgba(62,78,255,0)'"
        :maxLength="7"
        :isSmooth="false"
        tooltipFormatter="{c}%"
      ></LineChart>
    </CustomCard>
    <CustomCard title="写字楼平均租金分布" height="230px">
      <pieChart :echartData="pieData"></pieChart>
    </CustomCard>
    <CustomCard title="写字楼详细信息">
      <CustomTable
        :columns="columns"
        :list="tableList"
        :page="pages.page"
        :pageSize="pages.size"
        :total="total"
        @onChange="onPageChange"
      ></CustomTable>
    </CustomCard>
  </div>
</template>

<script>
import CustomData from "@/components/CustomData.vue";
import CustomCard from "@/components/CustomCard.vue";
import CustomTable from "@/components/CustomTable.vue";
import LineChart from "@/components/chart/lineChart.vue";
import PieChart from "@/components/chart/pieChart.vue";
import { GetCommonInfo } from "@/service";
import { formatFieldsList2 } from "js/utils.js";
export default {
  props: {
    polygon: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 概览详情卡片数据
      detailsCard: [
        { title: "写字楼数量", value: -1, unit: "个" },
        { title: "平均租金", value: -1, unit: "元/m²·天" },
        { title: "总建筑面积", value: -1, unit: "m²" },
      ],
      // 折线图数据
      lineData: [
        { name: "0-10", value: -1 },
        { name: "10-20", value: -1 },
        { name: "30-40", value: -1 },
        { name: "40-50", value: -1 },
        { name: "50-60", value: -1 },
        { name: "60以上", value: -1 },
      ],
      // 饼图数据
      pieData: [],
      // 表格表头
      columns: [
        {
          title: "序号",
          type: "index",
          width: 60,
          align: "center",
          className: "serial-number",
        },
        {
          title: "写字楼名称",
          key: "写字楼名称",
        },
        {
          title: "平均租金（元/m²·天）",
          key: "平均租金",
          width: 120,
        },
      ],
      // 表格数据
      tableList: [],
      // 表格总数
      total: 0,
      // 表格分页
      pages: {
        page: 1,
        size: 10000,
      },
    };
  },
  components: {
    CustomCard,
    CustomData,
    LineChart,
    PieChart,
    CustomTable,
  },
  watch: {
    polygon() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getOverview();
      this.getAcreage();
      this.getAverage();
      this.getDetails();
    },
    // 获取写字楼概览
    async getOverview() {
      let params = {
        identify: "sg-data-位置-写字楼概览",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: ["写字楼数量", "平均租金", "总建筑面积"],
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let details = res.data.items ? res.data.items[0] : [];
        this.detailsCard[0].value = details[0];
        this.detailsCard[1].value =
          details[1] || details[1] === 0 ? details[1].toFixed(2) : details[1];
        this.detailsCard[2].value =
          details[2] || details[2] === 0 ? details[2].toFixed(2) : details[2];
      }
    },
    // 获取写字楼占地面积
    async getAcreage() {
      let params = {
        identify: "sg-data-位置-写字楼占地面积",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: ["1万以下", "1-2万", "2-3万", "3万以上"],
      };
      let labels = ["1万平以下", "1-2万平", "2-3万平", "3万平以上"];
      let res = await GetCommonInfo(params);
      // console.log("写字楼占地面积", res);
      if (res && res.code === 200) {
        let fields = res.data.fields || [];
        let details = res.data.items ? res.data.items[0] : [];
        let list = [];
        for (let i = 0, n = fields.length; i < n; i++) {
          let val = details[i] || 0;
          list.push({
            name: labels[i],
            value: Math.floor(val * 100),
          });
        }
        this.lineData = list;
      }
    },
    // 获取写字楼平均租金
    async getAverage() {
      let params = {
        identify: "sg-data-位置-写字楼平均租金",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: ["5元以下", "5-10元", "10元以上"],
      };
      let res = await GetCommonInfo(params);
      // console.log("写字楼平均租金", res);
      if (res && res.code === 200) {
        let fields = res.data.fields || [];
        let details = res.data.items ? res.data.items[0] : [];
        let list = [];
        for (let i = 0, n = fields.length; i < n; i++) {
          let val = details[i] || 0;
          list.push({
            name: fields[i] + "/m²·天",
            value: val || 0,
          });
        }
        this.pieData = list;
      }
    },
    // 获取写字楼详细信息
    async getDetails() {
      let params = {
        identify: "sg-data-位置-写字楼详细信息",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: ["写字楼名称", "平均租金"],
        pages: this.pages,
      };
      let res = await GetCommonInfo(params);
      // console.log("写字楼详细信息", res);
      if (res && res.code === 200) {
        let list = formatFieldsList2(res.data || {});
        this.tableList = list;
        this.total = res.data.pages.total;
      }
    },
    // 小区详细信息表格页码改变
    async onPageChange(page) {
      this.pages.page = page;
      this.$emit("startLoading");
      await this.getDetails();
      this.$emit("endLoading");
    },
  },
};
</script>

<style lang="less" scoped></style>
