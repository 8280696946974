<template>
  <div class="tabs-box">
    <Tabs v-model="activeTab" @on-click="tabClick">
      <TabPane
        v-for="item in tabList"
        :label="item.name"
        :name="item.label"
        :key="item.label"
      ></TabPane>
    </Tabs>
  </div>
</template>
<script>
export default {
  name: "CustomTabs",
  props: {
    active: {
      type: String,
      default: "",
    },
    // 数据展示列表
    tabList: {
      type: Array,
      default: () => [
        { label: "小区", name: "小区" },
        { label: "写字楼", name: "写字楼" },
        { label: "商业", name: "商业" },
        { label: "学校", name: "学校" },
        { label: "医院", name: "医院" },
        { label: "公司企业", name: "公司企业" },
        { label: "交通", name: "交通" },
      ],
    },
  },
  data() {
    return {
      activeTab: "",
    };
  },
  mounted() {
    this.activeTab = this.active || this.tabList[0]?.label || "";
  },
  methods: {
    tabClick(name) {
      /*eslint-disable */
      console.log("tab点击触发", name);
      this.$emit("activeTab", name);
    },
  },
};
</script>
<style lang="less" scoped>
.tabs-box {
  /deep/ .ivu-tabs-bar {
    border-bottom: 1px solid #eeeeee;
  }
  /deep/ .ivu-tabs-nav {
    .ivu-tabs-ink-bar {
      display: none;
    }
    .ivu-tabs-tab {
      padding: 11px 0px;
      &:hover {
        color: #3519fb;
      }
    }
    .ivu-tabs-tab-active {
      color: #3519fb;
    }
  }
  /deep/ .ivu-tabs-nav-next,
  /deep/ .ivu-tabs-nav-prev {
    line-height: 43px;
  }
}
</style>
