import { render, staticRenderFns } from "./CustomTabs.vue?vue&type=template&id=4fdb6582&scoped=true"
import script from "./CustomTabs.vue?vue&type=script&lang=js"
export * from "./CustomTabs.vue?vue&type=script&lang=js"
import style0 from "./CustomTabs.vue?vue&type=style&index=0&id=4fdb6582&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fdb6582",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/mnt/www/jenkins/jenkins_home/workspace/product/qianlimu/online/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4fdb6582')) {
      api.createRecord('4fdb6582', component.options)
    } else {
      api.reload('4fdb6582', component.options)
    }
    module.hot.accept("./CustomTabs.vue?vue&type=template&id=4fdb6582&scoped=true", function () {
      api.rerender('4fdb6582', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/CustomTabs.vue"
export default component.exports