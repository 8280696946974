<template>
  <div class="supporting-box">
    <template v-if="isShowOverView">
      <OverView
        :address="address"
        icon="icon-marker-oval"
        :polygon="polygon"
        :list="overviewList"
      ></OverView>
    </template>
    <CustomTabs
      :active="activeTab"
      :tabList="tabList"
      @activeTab="clickTab"
    ></CustomTabs>
    <!-- <Housing ref="Housing" :polygon="polygon"></Housing> -->
    <component :is="activeTab" :polygon="polygon"></component>
  </div>
</template>

<script>
import OverView from "./OverView.vue";
import CustomTabs from "@/components/CustomTabs.vue";
import Housing from "@/components/location/SupportingModel/Housing.vue";
import Building from "@/components/location/SupportingModel/Building.vue";
import Business from "@/components/location/SupportingModel/Business.vue";
import School from "@/components/location/SupportingModel/School.vue";
import Hospital from "@/components/location/SupportingModel/Hospital.vue";
import Incorporated from "@/components/location/SupportingModel/Incorporated.vue";
import Traffic from "@/components/location/SupportingModel/Traffic.vue";
import { GetCommonInfo } from "@/service";
import { formatFieldsItem } from "js/utils.js";
export default {
  props: {
    polygon: {
      type: String,
      required: true
    },
    address: {
      type: String
    },
    isShowOverView: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      overviewList: [
        { label: "人口规模", rate: "人口规模比率", unit: "万人" },
        {
          label: "写字楼平均租金",
          rate: "写字楼平均租金比率",
          unit: "元/m²·天",
        },
        { label: "二手房房价", rate: "二手房房价比率", unit: "元/㎡" },
        { label: "小区", rate: "小区比率", unit: "个" },
        { label: "写字楼", rate: "写字楼比率", unit: "个" },
        { label: "学校", rate: "学校比率", unit: "个" },
        { label: "公司企业", rate: "公司企业比率", unit: "个" },
        { label: "医院", rate: "医院比率", unit: "家" },
        { label: "商场", rate: "商场比率", unit: "家" },
        { label: "便利店", rate: "便利店比率", unit: "家" },
        { label: "公共交通", rate: "公共交通比率", unit: "个" },
      ],
      // tab数据
      tabList: [
        { label: "Housing", name: "小区" },
        { label: "Building", name: "写字楼" },
        { label: "Business", name: "商业" },
        { label: "School", name: "学校" },
        { label: "Hospital", name: "医院" },
        { label: "Incorporated", name: "公司企业" },
        { label: "Traffic", name: "交通" },
      ],
      activeTab: "Housing",
    };
  },
  computed: {},
  watch: {
    polygon() {
      this.initData();
    },
  },
  methods: {
    initData() {
      for (let i = 0, n = this.overviewList.length; i < n; i++) {
        let item = this.overviewList[i];
        item.pic = "/images/location/location_overview" + (i + 1) + ".png";
        item.value = "";
      }
      if (!this.polygon) {
        return;
      }
      this.getAreaOverviewInfo();
    },
    async getLocationInfo() {},
    async getAreaOverviewInfo() {
      let fields = [];
      for (let i = 0, n = this.overviewList.length; i < n; i++) {
        let item = this.overviewList[i];
        fields.push(item.label);
        // fields.push(item.rate);
      }
      let identify = "sg-data-位置-区域总览";
      let params = {
        identify: identify,
        fields: fields,
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let dataItem = formatFieldsItem(res.data) || {};
        for (let i = 0, n = this.overviewList.length; i < n; i++) {
          let item = this.overviewList[i];
          // conole.log(dataItem[item.label],"dataItem[item.label]")
          let value =
            dataItem[item.label] || dataItem[item.label] === 0
              ? parseFloat(dataItem[item.label].toFixed(2))
              : dataItem[item.label];
          // value = parseFloat(value.toFixed(2));
          item.value = value;
          this.overviewList[i].value = value;
          this.$set(this.overviewList[i], "value", value);
          this.$set(this.overviewList, i, this.overviewList[i]);
        }
        // console.log(dataItem);
      }
    },
    // tab点击事件
    clickTab(name) {
      this.activeTab = name;
    },
  },
  mounted() {
    this.initData();
  },
  components: {
    OverView,
    CustomTabs,
    Housing,
    Building,
    Business,
    School,
    Hospital,
    Incorporated,
    Traffic,
  },
};
</script>

<style lang="less" scoped>
.supporting-box {
}
</style>
