var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "data-box",
    },
    _vm._l(_vm.dataList, function (item, index) {
      return _c(
        "div",
        {
          key: item.title,
          staticClass: "data-item",
          class: {
            "no-after":
              _vm.dataList.length >= _vm.rowNumber
                ? (index + 1) % _vm.rowNumber === 0
                : (index + 1) % _vm.dataList.length === 0,
            "no-margin-top": index < _vm.rowNumber,
          },
          style: {
            width:
              _vm.dataList.length >= _vm.rowNumber
                ? Math.floor(100 / _vm.rowNumber) + "%"
                : Math.floor(100 / _vm.dataList.length) + "%",
          },
        },
        [
          _c("div", [_vm._v(_vm._s(item.title))]),
          _c("div", [
            _c(
              "span",
              { staticClass: "data-item-value" },
              [
                item.unit == "户"
                  ? [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.value || item.value === 0
                              ? parseInt(item.value)
                              : "0"
                          ) +
                          " "
                      ),
                    ]
                  : [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.value || item.value === 0 ? item.value : "0"
                          ) +
                          " "
                      ),
                    ],
              ],
              2
            ),
            _vm._v(" " + _vm._s(item.unit) + " "),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }