<template>
  <!-- 医院 -->
  <div class="hospital-box">
    <CustomData :dataList="detailsCard"></CustomData>
    <CustomCard title="医院详情信息">
      <CustomTable
        :columns="columns"
        :list="tableList"
        :page="pages.page"
        :pageSize="pages.size"
        :total="total"
        @onChange="onPageChange"
      ></CustomTable>
    </CustomCard>
  </div>
</template>

<script>
import CustomData from "@/components/CustomData.vue";
import CustomCard from "@/components/CustomCard.vue";
import CustomTable from "@/components/CustomTable.vue";
import { GetCommonInfo } from "@/service";
import { formatFieldsList2 } from "js/utils.js";
export default {
  props: {
    polygon: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 概览详情卡片数据
      detailsCard: [
        { title: "综合医院数量", value: -1, unit: "个" },
        { title: "专科医院数量", value: -1, unit: "个" },
        { title: "社区医院数量", value: -1, unit: "个" },
      ],
      // 表格表头
      columns: [
        {
          title: "序号",
          type: "index",
          width: 60,
          align: "center",
          className: "serial-number",
        },
        {
          title: "医院名称",
          key: "名称",
        },
        {
          title: "医院类型",
          key: "类型",
          width: 120,
        },
      ],
      tableList: [],
      // 表格总数
      total: 0,
      // 表格分页
      pages: {
        page: 1,
        size: 10000,
      },
    };
  },
  components: {
    CustomCard,
    CustomData,
    CustomTable,
  },
  watch: {
    polygon() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getOverview();
      this.getDetails();
    },
    // 获取医院概览
    async getOverview() {
      let params = {
        identify: "sg-data-位置-医院",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: ["综合医院", "专科医院", "诊所"],
      };
      let res = await GetCommonInfo(params);
      // console.log("医院", res);
      if (res && res.code === 200) {
        let details = res.data.items ? res.data.items[0] : [];
        this.detailsCard[0].value = details[0] || 0;
        this.detailsCard[1].value = details[1] || 0;
        this.detailsCard[2].value = details[2] || 0;
      }
    },
    // 获取医院详细信息
    async getDetails() {
      let params = {
        identify: "sg-data-位置-医院详细信息",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: ["名称", "类型"],
        pages: this.pages,
      };
      let res = await GetCommonInfo(params);
      // console.log("医院详细信息", res);
      if (res && res.code === 200) {
        let list = formatFieldsList2(res.data || {});
        this.tableList = list;
        this.total = res.data.pages.total;
      }
    },
    // 医院详细信息表格页码改变
    onPageChange(page) {
      this.pages.page = page;
      this.getDetails();
    },
  },
};
</script>

<style lang="less" scoped></style>
